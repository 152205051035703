<template>
  <div class="cinema_testing">
<!--    <NavBar :title="title" class="nav_bottom"></NavBar>-->
    <main>
      <!--声道begin-->
      <div class="track">
        <div class="tip">
          <p>声道</p>
          <span>(支持6声道: 左、右、中、低频、左环、右环）</span>
        </div>
        <button @click="track_flag=true" :class="track_flag?'success':'error'">支持</button>
        <button @click="track_flag=false" :class="!track_flag?'success':'error'">缺失</button>
      </div>
      <!--声道end-->
      <!--响度begin-->
      <div class="loudness">
        <div class="title">
          <p>响度</p>
          <span>(主声道85,环绕声道82,低频声道91允差±3dBC)</span>
        </div>
        <div class="main">
          <div class="item" v-for="(v,index) in loudness_arr">
            <p>{{ v.name }}</p>
            <el-dropdown trigger="click" @command="getstate">
              <el-button type="primary">
                <i>{{ v.state }}</i><div class="bottom"></div>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforgetstate('正常',index)">正常</el-dropdown-item>
                <el-dropdown-item :command="beforgetstate('异常',index)">异常</el-dropdown-item>
                <el-dropdown-item :command="beforgetstate('故障',index)">故障</el-dropdown-item>
                <el-dropdown-item :command="beforgetstate('其他',index)">其他</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <input type="text" v-model="v.num" >
            <span class="state" :class="loudness_err(v)?'noOpacity':''">!</span>
          </div>

        </div>
      </div>
      <!--响度end-->
      <!--放映过程中交流声begin-->
      <div class="ac_sound">
        <div class="tip">
          放映过程中干扰声
        </div>
        <button @click="ac_sound_flag=false" :class="!ac_sound_flag?'success':'error'">有</button>
        <button @click="ac_sound_flag=true" :class="ac_sound_flag?'success':'error'">无</button>
      </div>
      <!--放映过程中交流声end-->
      <!--银幕中心亮度2D11-17F1begin-->
      <div class="brightness_2d">
        <div class="tip">
          银幕中心亮度2D<br>11-17F1
        </div>
        <input type="text" v-model="brightness_2d_text">
        <span class="state" :class="brightness_2d_err?'':'noOpacity'">!</span>
      </div>
      <!--银幕中心亮度2D     11-17F1end-->
      <!--银幕中心亮度3D大于 3.975F1begin-->
      <div class="brightness_3d">
        <div class="tip">
          银幕中心亮度3D<br>大于 3.975F1
        </div>
        <input type="text" @click="brightness_3d_flag=true" v-model="brightness_3d_text" >
        <span class="state" :class="brightness_3d_err?'':'noOpacity'" >!</span>
        <button @click="brightness_3d_flag=false" :class="!brightness_3d_flag?'success':'error'">无</button>
      </div>
      <!--银幕中心亮度3D大于 3.975F1end-->
      <!--清晰度begin-->
      <div class="definition">
        <div class="tip">
          清晰度
        </div>
        <button @click="definition_flag=true" :class="definition_flag?'success':'error'">正常</button>
        <button @click="definition_flag=false" :class="!definition_flag?'success':'error'">模糊</button>
      </div>
      <!--清晰度end-->
      <!--汇聚begin-->
      <div class="convergence">
        <div class="tip">
          <p>汇聚</p>
          <span>像素偏差0.5个像素（含）</span>
        </div>
        <button @click="convergence_flag=true" :class="convergence_flag?'success':'error'">正常</button>
        <button @click="convergence_flag=false" :class="!convergence_flag?'success':'error'">偏差</button>
      </div>
      <!--汇聚end-->
      <!--座位数begin-->
<!--      <div class="seats_num">
        <div class="tip">
          座位数
        </div>
        <input type="text" v-model="seats" >
      </div>-->
      <!--座位数end-->
      <!--设备信息begin-->
      <div class="device_info">
        <p>设备信息</p>
        <div class="device_projector">
          <div class="tip">
            放映机
          </div>
          <div>
            <el-dropdown trigger="click" @command="getProjector">
              <el-button type="primary">
                <i>{{ projector }}</i><div class="bottom"></div>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="巴可（BARCO）">巴可（BARCO）</el-dropdown-item>
                <el-dropdown-item command="科视（Christie）">科视（Christie）</el-dropdown-item>
                <el-dropdown-item command="索尼（sony）">索尼（sony）</el-dropdown-item>
                <el-dropdown-item command="NEC">NEC</el-dropdown-item>
                <el-dropdown-item command="IMAX">IMAX</el-dropdown-item>
                <el-dropdown-item command="中国巨幕">中国巨幕</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="device_server">
          <div class="tip">
            服务器
          </div>
          <el-dropdown trigger="click" @command="getServer" v-show="!server_flag">
            <el-button type="primary">
              <i>{{ server }}</i><div class="bottom"></div>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="巴可（BARCO）ICMP">巴可（BARCO）ICMP</el-dropdown-item>
              <el-dropdown-item command="GDC">GDC</el-dropdown-item>
              <el-dropdown-item command="SONY-XCT-S10">SONY-XCT-S10</el-dropdown-item>
              <el-dropdown-item command="杜比（DOLBY）">杜比（DOLBY）</el-dropdown-item>
              <el-dropdown-item command="doremi">doremi</el-dropdown-item>
              <el-dropdown-item command="智辰">智辰</el-dropdown-item>
              <el-dropdown-item command="蒙太奇">蒙太奇</el-dropdown-item>
              <el-dropdown-item command="其他">其他</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <input type="text" v-show="server_flag" v-model="server" class="one_input" @blur="server_flag=false">

        </div>
        <div class="device_3d">
          <div class="tip">
            3D设备
          </div>
          <el-dropdown trigger="click" @command="getEquipment" v-show="!equipment_flag">
            <el-button type="primary">
              <i>{{ equipment }}</i><div class="bottom"></div>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="单光路">单光路</el-dropdown-item>
              <el-dropdown-item command="双光路">双光路</el-dropdown-item>
              <el-dropdown-item command="三光路">三光路</el-dropdown-item>
              <el-dropdown-item command="其他">其他</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <input type="text" v-show="equipment_flag" v-model="equipment" class="one_input" @blur="equipment_flag=false">
        </div>
        <div class="device_decoder">
          <div class="tip">
            解码器
          </div>
          <el-dropdown trigger="click" @command="getDecoder" v-show="!decoder_flag">
            <el-button type="primary">
              <i>{{ decoder }}</i><div class="bottom"></div>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="飞达">飞达</el-dropdown-item>
              <el-dropdown-item command="音霸">音霸</el-dropdown-item>
              <el-dropdown-item command="杜比（DOLBY）">杜比（DOLBY）</el-dropdown-item>
              <el-dropdown-item command="音王">音王</el-dropdown-item>
              <el-dropdown-item command="惠声">惠声</el-dropdown-item>
              <el-dropdown-item command="湖山">湖山</el-dropdown-item>
              <el-dropdown-item command="GDC临境音">GDC临境音</el-dropdown-item>
              <el-dropdown-item command="其他">其他</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <input type="text" v-show="decoder_flag" v-model="decoder" class="one_input" @blur="decoder_flag=false">
        </div>
      </div>
      <!--设备信息end-->
      <!--  备注begin  -->
      <div class="beizhu">
        <p>备注</p>
        <van-field
            v-model="other"
            rows="1"
            autosize
            type="textarea"
            placeholder="请输入备注"
        />
      </div>
      <!--  备注end  -->
    </main>
    <div class="submit">
      <el-button class="btn_sub" @click="sub" :loading="sub_flag">确认</el-button>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import {Collapse, CollapseItem, Toast, Field, Dialog} from 'vant';
import { Dropdown,DropdownMenu, DropdownItem,Button,Loading } from 'element-ui';
import {GetOneHall, SetTestingData} from '../axios/api'
export default {
  name: "CinemaTesting",
  created() {
    document.title=this.$route.params.name;
    this.title=this.$route.params.name; //设置标题名字
    if(this.$route.params.hallid){
      //检测到hallid 查看已经填写过的数据
      let loadingInstance = Loading.service({ fullscreen: true , text: "加载中"});
      GetOneHall({
        hallid:this.$route.params.hallid,
        token:localStorage.getItem("token")
      }).then(respose=>{
        if(respose.state==="1"){
          console.log(respose.data)
          /*初始化数据*/
          this.track_flag=respose.data.channel==="支持"
          this.ac_sound_flag=respose.data.hum==="无"
          this.brightness_2d_text=respose.data.bright2D
          this.brightness_3d_flag=respose.data.bright3D!=="无"?this.brightness_3d_text=respose.data.bright3D:false
          this.definition_flag=respose.data.clarity==="正常"
          this.convergence_flag=respose.data.converge==="正常"
          this.loudness_arr[0].num=respose.data.middle
          this.loudness_arr[0].state=respose.data.exmiddle
          this.loudness_arr[1].num=respose.data.left
          this.loudness_arr[1].state=respose.data.exleft
          this.loudness_arr[2].num=respose.data.right
          this.loudness_arr[2].state=respose.data.exright
          this.loudness_arr[3].num=respose.data.leftloop
          this.loudness_arr[3].state=respose.data.exleftloop
          this.loudness_arr[4].num=respose.data.rightloop
          this.loudness_arr[4].state=respose.data.exrightloop
          this.loudness_arr[5].num=respose.data.lf
          this.loudness_arr[5].state=respose.data.exlf
          this.projector=respose.data.projector
          this.server=respose.data.server
          this.equipment=respose.data.equipment
          this.decoder=respose.data.decoder
          this.other = respose.data.note
          setTimeout(()=>{
            this.localStorage_flag = true //赋值完成 打开缓存开关
          },1000)
        }
        loadingInstance.close();
      }).catch(error=>{
        console.log(error)
      })
    }else{
      //空白页面 直接打开缓存开关
      this.localStorage_flag = true
    }
    this.isHistory()
  },
  components:{
    NavBar,
    [Collapse.name]:Collapse,
    [CollapseItem.name]:CollapseItem,
    [Dropdown.name]:Dropdown,
    [DropdownMenu.name]:DropdownMenu,
    [DropdownItem.name]:DropdownItem,
    [Button.name]:Button,
    [Field.name]:Field
  },
  data(){
    return{
      title:'1号影院厅',
      track_flag:true, // 声道 true为支持,false缺失
      loudness_arr:[ // 响度数组
        {name:'中声道',state:'正常',num:''},
        {name:'左声道',state:'正常',num:''},
        {name:'右声道',state:'正常',num:''},
        {name:'左环绕',state:'正常',num:''},
        {name:'右环绕',state:'正常',num:''},
        {name:'低频',state:'正常',num:''},
      ],
      ac_sound_flag:true, // 放映过程中交流声 true为无,false有
      brightness_2d_text:'', // 银幕中心亮度2D 11-17F1
      brightness_3d_flag:true, // 银幕中心亮度3D大于 3.975F1 ture在文本框输入 false无
      brightness_3d_text:'', // 银幕中心亮度3D大于 3.975F1
      definition_flag:true, // 清晰度 true为正常,false模糊
      convergence_flag:true, //汇聚 true为正常,false偏差
      projector:'巴可（BARCO）', // 放映机
      server:'巴可（BARCO）ICMP', // 服务器
      server_flag:false,
      equipment:'单光路', // 3D设备
      equipment_flag:false,
      decoder:'飞达', // 解码器
      decoder_flag:false,
      other:'', //备注
      sub_flag:false,
      localStorage_flag:false, //是否可以缓存
    }
  },
  methods:{
    getstate(v){
      this.loudness_arr[v.index].state=v.name
    },
    beforgetstate(v,i){
      return{
        'name':v,
        'index':i
      }
    },
    getProjector(v){
      this.projector=v;
    },
    getServer(v){
      this.server=v;
      if(v==="其他"){
        this.server_flag=true
        this.server=""
      }
    },
    getEquipment(v){
      this.equipment=v;
      if(v==="其他"){
        this.equipment_flag=true
        this.equipment=""
      }
    },
    getDecoder(v){
      this.decoder=v;
      if(v==="其他"){
        this.decoder_flag=true
        this.decoder=""
      }
    },
    sub(){
      /*首先检测数据是否都填写*/
      if(!this.$route.params.mid){
        Toast.fail({message:"mid影厅获取失败"})
        return
      }
      if(!this.$route.params.hid){
        Toast.fail({message:"hid获取失败"})
        return
      }
      if(!localStorage.getItem("did")){
        Toast.fail({message:"did获取失败"})
        return
      }
      if(this.loudness_arr.every(v=>v.num!="") && this.brightness_2d_text!='' && (this.brightness_3d_flag?this.brightness_3d_text:true)){
        /*提交检测结果*/
        this.sub_flag=true
        SetTestingData({
          mid:this.$route.params.mid,
          hid:this.$route.params.hid,
          did:localStorage.getItem("did"),
          token:localStorage.getItem("token"),
          channel:this.track_flag?"支持":"缺失",
          hum:this.ac_sound_flag?"无":"有",
          bright2D:this.brightness_2d_text,
          bright3D:this.brightness_3d_flag?this.brightness_3d_text:"无",
          clarity:this.definition_flag?"正常":"模糊",
          converge:this.convergence_flag?"正常":"偏差",
          middle:this.loudness_arr[0].num,
          exmiddle:this.loudness_arr[0].state,
          left:this.loudness_arr[1].num,
          exleft:this.loudness_arr[1].state,
          right:this.loudness_arr[2].num,
          exright:this.loudness_arr[2].state,
          leftloop:this.loudness_arr[3].num,
          exleftloop:this.loudness_arr[3].state,
          rightloop:this.loudness_arr[4].num,
          exrightloop:this.loudness_arr[4].state,
          lf:this.loudness_arr[5].num,
          exlf:this.loudness_arr[5].state,
          projector:this.projector,
          server:this.server,
          equipment:this.equipment,
          decoder:this.decoder,
          note:this.other
        }).then(respose=>{
          if(respose.state==="1"){
            localStorage.removeItem('CinemaTesting')
            /*this.$router.go(-1);*/
            /*判断本次操作是修改还是首次*/
            if(this.$route.params.hallid){
              Toast.success("修改成功")
            }else{
              Toast.success("提交成功")
            }
            this.sub_flag=false
            this.$router.replace({name:"SelectionHall",params:{id:this.$route.params.mid}})
          }else{
            Toast({message:"操作失败"+respose.msg,icon:"cross"})
          }
        }).catch(error=>{
          console.log(error);
        })
      }else{
        Toast.fail("未填写内容")
      }
    },
    /*将数据缓存*/
    setItem(){
      if(!this.localStorage_flag){
        return
      }
      let arr = {
        track_flag:this.track_flag,
        ac_sound_flag:this.ac_sound_flag,
        brightness_2d_text:this.brightness_2d_text,
        brightness_3d_flag:this.brightness_3d_flag,
        brightness_3d_text:this.brightness_3d_flag?this.brightness_3d_text:'',
        definition_flag:this.definition_flag,
        convergence_flag:this.convergence_flag,
        loudness_arr:this.loudness_arr,
        projector:this.projector,
        server:this.server,
        equipment:this.equipment,
        decoder:this.decoder,
        other:this.other,
        id:this.$route.params.hid, //键值
      }
      localStorage.setItem('CinemaTesting',JSON.stringify(arr))
    },
    /*检测是否存在需要恢复的数据*/
    isHistory(){
      if(localStorage.getItem('CinemaTesting')){
        let arr = JSON.parse(localStorage.getItem('CinemaTesting'))
        if(arr.id == this.$route.params.hid){ //当前影厅id和缓存里的一致时
          Dialog.confirm({
            title: '提示',
            message: '检测到存在未提交的数据',
            getContainer : ".cinema_testing",
            confirmButtonText:'恢复',
            cancelButtonText:'清除'
          }).then(()=>{
            this.track_flag=arr.track_flag
            this.ac_sound_flag=arr.ac_sound_flag
            this.brightness_2d_text=arr.brightness_2d_text
            this.brightness_3d_flag=arr.brightness_3d_flag
            this.brightness_3d_text=arr.brightness_3d_text
            this.definition_flag=arr.definition_flag
            this.convergence_flag=arr.convergence_flag
            this.loudness_arr=arr.loudness_arr
            this.projector=arr.projector
            this.server=arr.server
            this.equipment=arr.equipment
            this.decoder=arr.decoder
            this.other=arr.other
          }).catch(()=>{
            localStorage.removeItem('CinemaTesting')
          });
        }
      }
    }
  },
  computed:{
    loudness_err(){
      return v=>{
        if(v.state!='正常'){
          return false
        }
        if(v.num){
          switch (v.name){
            case '中声道':
              return true
              break;
            case '左声道':
            case '右声道':
              return this.loudness_arr[0].num-6<=v.num-0 && v.num-0<=this.loudness_arr[0].num-0+6
              break;
            case '右环绕':
            case '左环绕':
              return this.loudness_arr[0].num-9<=v.num-0 && v.num-0<=this.loudness_arr[0].num-0+3
              break;
            case '低频':
              return this.loudness_arr[0].num-0<=v.num-0 && v.num-0<=this.loudness_arr[0].num-0+12
              break;
            default:
              break
          }
        }else{
          return true
        }
      }
    },
    brightness_2d_err(){
      if(this.brightness_2d_text){
        return this.brightness_2d_text<=11
      }
    },
    brightness_3d_err(){
      if(!this.brightness_3d_flag){
        return false
      }
      if(this.brightness_3d_text){
        return this.brightness_3d_text<3.5
      }
    },
  },
  watch:{
    //监听所有的  首次加载时导致的数据改变导致的缓存操作
    track_flag(){
      this.setItem()
    },
    ac_sound_flag(){
      this.setItem()
    },
    brightness_2d_text(){
      this.setItem()
    },
    brightness_3d_flag(){
      this.setItem()
    },
    brightness_3d_text(){
      this.setItem()
    },
    definition_flag(){
      this.setItem()
    },
    convergence_flag(){
      this.setItem()
    },
    loudness_arr:{
      handler() {
        this.setItem()
      },
      deep: true,
    },
    projector(){
      this.setItem()
    },
    server(){
      this.setItem()
    },
    equipment(){
      this.setItem()
    },
    decoder(){
      this.setItem()
    },
    other(){
      this.setItem()
    },
  }
}
</script>

<style scoped>
/*.nav_bottom{*/
/*  margin-bottom: 27px*/
/*}*/
.cinema_testing{
  padding-top: 27px;
}
main > * {
  margin: 0 auto;
}
/*全局倒三角样式*/
.bottom {
  height: 0;
  width: 0;
  border: 9px solid transparent;
  border-top: 16px solid #656565;
  display: inline-block;
  position: relative;
  top: 17px;
  left: -20px;
  float: right;
}
.success {
  background: #5F7BFF;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
.error {
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #656565;
  background: #EFEFEF;
}
.one_input {
  width: 131px;
  height: 50px;
  border-radius: 7px;
  border: 2px solid #5F7BFF;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #5F7BFF;
  text-align: center;
}
main {
  width: 690px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 0 auto 52px;
  padding-top: 37px;
}
main .track {
  height: 108px;
  width: 621px;
  border-bottom: 2px solid #F2F2F2;
  padding-left: 5px;
  padding-bottom: 52px;
  display: flex;
  align-items: center;
}
main .track .tip {
  width: 283px;
  height: 110px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}
main .track .tip p {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  margin: 0;
}
main .track .tip span {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.56);
  line-height: 35px;
}
main .track button {
  cursor: pointer;
  width: 135px;
  height: 54px;
  border-radius: 7px;
  border: 0;
}
main .track button:nth-of-type(1) {
  margin-right: 40px;
}
main .loudness {
  margin-top: 51px;
  height: 582px;
  width: 626px;
  padding-bottom: 35px;
  border-bottom: 2px solid #F2F2F2;
}
main .loudness .title {
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
main .loudness .title p {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  width: 123px;
}
main .loudness .title span {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.56);
}
main .loudness .main {
  padding-top: 81px;
}
main .loudness .main .item {
  margin: 0 0 24px 38px;
  width: 588px;
  height: 54px;
  display: flex;
  align-items: center;
}
main .loudness .main .item p {
  width: 241px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin: 0;
}
main .loudness .main .item /deep/ .el-dropdown {
  margin-right: 40px;
  width: 133px;
  height: 54px;
  background: #EFEFEF;
  border-radius: 7px;
}
main .loudness .main .item /deep/ .el-dropdown button {
  width: 133px;
  height: 54px;
  background: #EFEFEF;
  border-radius: 7px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #707070;
  border: 0;
  padding: 0;
  text-align: center;
}
/*display: flex;
              align-items: center;
              justify-content: start;*/
main .loudness .main .item /deep/ .el-dropdown button span i {
  text-align: center;
  font-style: normal;
}
main .loudness .main .item /deep/ .el-dropdown button span .bottom {
  border: 9px solid transparent;
  border-top: 16px solid #656565;
  top: 12px;
  left: -13px;
}
/*.select{
          width: 110px;
          height: 42px;
          background: #EFEFEF;
          border-radius: 7px ;
          border: none;
          margin-right: 40px;
          outline: none;
          font-size: 30px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #707070;
          padding: 6px 0 6px 23px;
        }*/
/*select{
          width: 133px;
          height: 54px;
          background: #EFEFEF;
          border-radius: 7px ;
          border: none;
          margin-right: 40px;
          outline: none;
          font-size: 30px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #707070;
          padding: 6px 0 6px 23px;

          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;

          &:after{
            content: "ss";
            width: 14px;
            height: 8px;
            position: absolute;
            right: 20px;
            top: 10px;
            background-color: #516FFA;

            //width: 0px;
            //height: 0px;
            //border-top: 22px solid #707070;
            //border-right: 22px solid #707070;
            //border-left: transparent;
            //border-bottom: transparent;
            //position: absolute;
            //top: 0;
            //left: 0;
          }
          option{
            width: 100px;
            height: 54px;
          }
        }*/
main .loudness .main .item input {
  width: 131px;
  height: 48px;
  border-radius: 7px;
  border: 2px solid #5F7BFF;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #707070;
  text-align: center;
}
main .ac_sound {
  width: 626px;
  padding-top: 51px;
  padding-bottom: 45px;
  border-bottom: 2px solid #F2F2F2;
  display: flex;
  align-items: center;
}
main .ac_sound .tip {
  margin: 0 57px 0 5px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  width: 248px;
}
main .ac_sound button {
  cursor: pointer;
  width: 135px;
  height: 54px;
  border-radius: 7px;
  border: 0;
}
main .ac_sound button:nth-of-type(1) {
  margin-right: 40px;
}
main .brightness_2d {
  width: 626px;
  padding-top: 53px;
  padding-bottom: 50px;
  border-bottom: 2px solid #F2F2F2;
  display: flex;
  align-items: center;
}
main .brightness_2d .tip {
  margin: 0 67px 0 0;
  width: 240px;
  height: 84px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 40px;
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
}
main .brightness_2d input {
  width: 131px;
  height: 50px;
  border-radius: 7px;
  border: 2px solid #5F7BFF;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #5F7BFF;
  text-align: center;
}
main .brightness_3d {
  width: 626px;
  padding-top: 53px;
  padding-bottom: 50px;
  border-bottom: 2px solid #F2F2F2;
  display: flex;
  align-items: center;
}
main .brightness_3d .tip {
  margin: 0 67px 0 0px;
  width: 240px;
  height: 84px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 40px;
  text-align: center;
}
main .brightness_3d input {
  width: 131px;
  height: 50px;
  border-radius: 7px;
  border: 2px solid #5F7BFF;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #5F7BFF;
  text-align: center;
}
main .brightness_3d button {
  margin-left: 20px;
  cursor: pointer;
  width: 135px;
  height: 54px;
  border-radius: 7px;
  border: 0;
}
main .definition {
  width: 626px;
  padding-top: 51px;
  padding-bottom: 45px;
  border-bottom: 2px solid #F2F2F2;
  display: flex;
  align-items: center;
  padding: 56px 0;
}
main .definition button {
  cursor: pointer;
  width: 135px;
  height: 54px;
  border-radius: 7px;
  border: 0;
}
main .definition button:nth-of-type(1) {
  margin-right: 40px;
}
main .definition .tip {
  width: 314px;
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 35px;
}
main .convergence {
  height: 108px;
  width: 621px;
  border-bottom: 2px solid #F2F2F2;
  padding-left: 5px;
  padding-bottom: 52px;
  display: flex;
  align-items: center;
  padding: 56px 0 78px;
  height: 76px;
}
main .convergence .tip {
  width: 283px;
  height: 110px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}
main .convergence .tip p {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  margin: 0;
}
main .convergence .tip span {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.56);
  line-height: 35px;
}
main .convergence button {
  cursor: pointer;
  width: 135px;
  height: 54px;
  border-radius: 7px;
  border: 0;
}
main .convergence button:nth-of-type(1) {
  margin-right: 40px;
}
main .convergence .tip {
  justify-content: center;
}
/*main .seats_num {
  width: 626px;

  padding-bottom: 141px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
main .seats_num .tip {
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
}
main .seats_num input {
  width: 131px;
  height: 50px;
  border-radius: 7px;
  border: 2px solid #5F7BFF;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #5F7BFF;
  text-align: center;
}*/
main .device_info {
  padding-top: 56px;
  width: 644px;
  border-bottom: 2px solid #F2F2F2;
}
main .beizhu{
  padding-top: 56px;
  width: 644px;
  padding-bottom: 40px;
}
main .beizhu p{
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.69);
  margin: 0 0 40px;
}
main .beizhu /deep/ .van-cell{
  width: 100%;
  border-radius: 7px;
  border: 1px solid #b6b6b6;
  font-size: 32px;
  padding: 10px 16px;
}
main .beizhu /deep/ .van-cell::-webkit-scrollbar , main .beizhu /deep/ .van-cell textarea::-webkit-scrollbar{
  display:none
}
main .device_info .tip {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
}
main .device_info button {
  width: 294px;
  height: 54px;
  background: #EFEFEF;
  border-radius: 7px;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  padding: 0;
  line-height: 54px;
  border: 0;
}
main .device_info button span {
  display: flex;
  align-items: center;
}
main .device_info button span i {
  max-width: 271px;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  font-style: normal;
}
main .device_info button span .bottom {
  top: 22px;
}
main .device_info p {
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.69);
  margin: 0 0 91px;
}
main .device_info .device_projector {
  width: 626px;
  margin-left: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 72px;
  border-bottom: 2px solid #F2F2F2;
  margin-top: 56px;
}
main .device_info .device_server {
  width: 626px;
  margin-left: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 72px;
  border-bottom: 2px solid #F2F2F2;
  margin-top: 56px;
}
main .device_info .device_3d {
  width: 626px;
  margin-left: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 72px;
  border-bottom: 2px solid #F2F2F2;
  margin-top: 56px;
  padding-bottom: 49px;
  margin-top: 63px;
}
main .device_info .device_decoder {
  width: 626px;
  margin-left: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 72px;
  border-bottom: 2px solid #F2F2F2;
  margin-top: 56px;
  border-bottom: none;
  padding-bottom: 86px;
  margin-top: 70px;
}
.submit {
  width: 750px;
  height: 166px;
  background: #FFFFFF;
  box-shadow: 0 4px 13px 5px rgba(112, 112, 112, 0.06);
  text-align: center;
}
.submit .btn_sub {
  margin-top: 18px;
  width: 691px;
  height: 89px;
  border: 0;
  padding: 0;
  background: linear-gradient(270deg, #3054F8 0%, #4768FF 100%);
  box-shadow: 0 0 18px 3px rgba(66, 95, 220, 0.35);
  border-radius: 41px 41px 41px 41px;
  opacity: 0.87;
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
  cursor: pointer;
}
.state{
  font-weight: bold;
  color: red;
  margin-left: 10px;
  font-size: 30px;
}
.isOpacity{
  opacity: 1;
}
.noOpacity{
  opacity: 0;
}
>>>.van-dialog{
  width: 640px;
}
>>>.van-dialog__header{
  padding-top: 26px;
  padding-bottom: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  font-size: 32px;
}
>>>.van-dialog__message{
  font-size: 28px;
  padding-top: 20px;
}
.cinema_testing>>>.van-dialog .van-button__text {
  color: #5270FB;
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
}
.cinema_testing>>>.van-dialog__footer {
  padding: 0;
  margin: 0 auto;
  width: 552px;
  height: 100px;
  border-top: 1px solid #F2F2F2;
}
.cinema_testing>>>.van-dialog__footer button{
  height: 100px;
  border: none!important;
  /*font-size: 16px;*/
}
</style>

<style>
/*因为下拉列表是在app外，所以用全局样式修改*/
/*ul样式*/
.el-dropdown-menu{
  min-width: 133px;
  overflow: hidden;
}
/*li样式*/
.el-dropdown-menu__item{
  height: 54px;
  font-size: 28px;
  font-family: PingFang SC-Medium, PingFang SC;
  line-height: 54px;
  padding: 0 10px;
  text-align: center;
}
</style>
